import "../../assets/css/font-awesome.css";
import "./benefits.css";
import Zoom from "react-reveal/Zoom";
import Heart from "../../assets/img/heart-attack.png";
import LaptopHouse from "../../assets/img/work-from-home.png";
import Carrot from "../../assets/img/carrot.png";
import Feather from "../../assets/img/feather.png";
export default function Benefits() {
  return (
    <section className="grids-bottom bg-light py-md-5 py-3">
      <div className="container">
        <Zoom left cascade duration={500}>
          <div className="row">
            <div className="col-lg-3 col-md-12 about-in text-left">
              <div className="card">
                <div className="card-body">
                  <img className="benefit-img" src={LaptopHouse} alt="" />
                  <h5 className="card-title">بيئه تربيه مميزه</h5>

                  <div className="line" data-blast="bgColor" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 about-in text-left">
              <div className="card">
                <div className="card-body">
                  <img className="benefit-img" src={Carrot} alt="" />
                  <h5 className="card-title">افضل انواع التغذيه</h5>
                  <div className="line" data-blast="bgColor" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 about-in text-left">
              <div className="card">
                <div className="card-body">
                  <img className="benefit-img" src={Heart} alt="" />
                  <h5 className="card-title"> اقوي برنامج وقائي و علاجي</h5>
                  <div className="line" data-blast="bgColor" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 about-in text-left">
              <div className="card">
                <div className="card-body">
                  <img className="benefit-img" src={Feather} alt="" />
                  <h5 className="card-title">خطوط دماء قويه</h5>
                  <div className="line" data-blast="bgColor" />
                </div>
              </div>
            </div>
          </div>
        </Zoom>
      </div>
    </section>
  );
}
