import "./about.css";
import Fade from "react-reveal/Fade";
import sanityClient from "../../sanity/sanity";
import { useEffect, useState } from "react";
import urlFor from "../../util/imageBuilder";

export default function About() {
  const [aboutData, setAboutData] = useState(null);
  const query = '*[_type == "about"]{image,name,paragraph,expertise}';
  useEffect(() => {
    sanityClient
      .fetch(query)
      .then((data) => {
        setAboutData(data[0]);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <section id="about" className="about-section">
      {aboutData !== null ? (
        <>
          <Fade right>
            <div
              className="about-img"
              style={{
                backgroundImage: `url(${urlFor(
                  aboutData.image.asset._ref
                ).url()})`,
              }}
            ></div>
          </Fade>
          <Fade left>
            <div className="about-text">
              <h1 className="about-text-header-primary"> {aboutData.name} </h1>
              <h2 className="about-text-header-secondary">
                {aboutData.expertise}
              </h2>
              <p className="about-text-description">{aboutData.paragraph}</p>
            </div>
          </Fade>
        </>
      ) : null}
    </section>
  );
}
