import "./service.css";
import Fade from "react-reveal/Fade";
import urlFor from "../../util/imageBuilder";
import { useState } from "react";

export default function Service(props) {
  const { title, desc, image, imgTitle } = props;
  const [showMore, setShowMore] = useState(false);

  console.log(desc);
  console.log(desc.length);
  return (
    <Fade right cascade duration={500}>
      <div className="row choose-main mb-lg-4">
        <div className="col-lg-6 galsses-grid-right">
          <div className="galsses-grid-left">
            <figure className="effect-lexi image-figure">
              <img
                src={urlFor(image).url()}
                alt="pet grooming"
                className="img-fluid"
              />

              <div className="image-title">{imgTitle}</div>
            </figure>
          </div>
        </div>
        <div className="col-lg-6 galsses-grid-right">
          {/*--
                  <h5>
                      <span class="post-color">21</span> May 2018</h5>
                   <!--*/}
          <h4 className="post ">{title}</h4>
          <div className="line" data-blast="bgColor" />
          <p
            className={`mt-3 ${
              !showMore
                ? "service-paragraph-clamped"
                : "service-paragraph-extended"
            }`}
          >
            {desc}
          </p>
          {desc && desc.length > 200 ? (
            <div className="log-in mt-md-4 mt-2">
              <a
                className="btn text-uppercase readmore-btn"
                data-blast="bgColor"
                onClick={(e) => {
                  setShowMore(!showMore);
                  e.preventDefault();
                }}
              >
                {!showMore ? " تعرف علي المزيد " : "اظهر  الملخص فقط"}
              </a>
            </div>
          ) : null}
        </div>
      </div>
    </Fade>
  );
}
