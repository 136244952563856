import Template from "../../components/template/template";
import Header from "../../components/header/header";
import Benefits from "../../components/benefits/benefits";
import Services from "../../components/services/services";
import About from "../../components/about/about";
import Testimonials from "../../components/testinmonials/testimonials";
import LeadMagnetForm from "../../components/leadMangetForm/leadMagnetForm";
import MyProducts from "../../components/myProducts/myProducts";
import LoadingOverlay from "../../components/loadingOverlay/loadingOverlay";
import SideButton from "../../components/sideButton/sideButton";
import sanityClient from "../../sanity/sanity";
import Gallery from "../../components/gallery/gallery";

export default function Landing() {
  const query = '*[_type == "gallery"]';
  sanityClient
    .fetch(query)
    .then((data) => {})
    .catch((e) => console.log(e));

  return (
    <Template>
      <div>
        <div className="main">
          <div id="page">
            <Header></Header>
          </div>
        </div>

        {/* Loading overlay
    ================================================== */}
        <LoadingOverlay />
        {/* Benefits
    ================================================== */}
        <Benefits />
        {/* Services
    ================================================== */}
        <Services />
        {/* About 
    ================================================== */}
        <About />
        {/* Gallery
    ================================================== */}
        <Gallery />

        {/* Telegram Form
    ================================================== */}

        <LeadMagnetForm />
        {/* Testimonials
    ================================================== */}
        <Testimonials />

        {/* Products slider
    ================================================== */}
        <MyProducts />

        {/* Youtube Side button
    ================================================== */}
        <SideButton />
      </div>
    </Template>
  );
}
