import "./leadMagnet.css";
import { PureComponent } from "react";
import { onSnapshot, collection, addDoc } from "firebase/firestore";

// import validator from "validator";
// import shortid from "shortid";
import Zoom from "react-reveal/Zoom";
// import { useState, useEffect } from "react";

import db from "../../firebase";

export default class LeadMagnetForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      magnetType: "جروب الفيسبوك",
      buttonDisable: true,
      magnetMessage: "",
      showPhoneAlert: false,
      isPhoneValid: false,
      phoneNumber: null,
      magnetsMap: [
        {
          magnetType: "جروب الفيسبوك",
          link: `https://www.facebook.com/groups/921144492168115/?ref=share`,
        },

        {
          magnetType: "جروب التيليجرام",
          link: "https://t.me/joinchat/QwSw1KfEcEZhOWJk",
        },
      ],
    };
  }

  handleAddingNewNumber = (clientNumber) => {
    // note#1

    const collectionRef = collection(db, "clientsNumbers");
    onSnapshot(collectionRef, async (snapshot) => {
      const duplicate = snapshot.docs.find(
        (doc) => doc.data().number === clientNumber
      );

      if (duplicate === undefined) {
        const payload = { number: clientNumber };
        await addDoc(collectionRef, payload);
      }
    });
  };

  handleFormSubmission = (e) => {
    e.preventDefault();
    const { magnetsMap, magnetType, isPhoneValid } = this.state;
    const magnet = magnetsMap.find(
      (magnet) => magnet.magnetType === magnetType
    );
    if (isPhoneValid && magnetType) {
      const clientNumber = this.state.phoneNumber;

      this.handleAddingNewNumber(clientNumber);
      // const res = await this.props.db
      //   .collection("clientsNumbers")
      //   .doc("client")
      //   .set(clientNumber);
      window.location.href = magnet.link;
    } else {
      this.setState({ showPhoneAlert: true }, () =>
        setTimeout(() => {
          this.setState({ showPhoneAlert: false });
        }, 3500)
      );
    }
  };
  validatePhoneNumber = (e) => {
    var phoneno = /^0\d{10}$/;

    const phoneNumber = e.target.value;
    console.log(phoneNumber);
    if (phoneNumber.match(phoneno)) {
      console.log("in");
      this.setState({ isPhoneValid: true, phoneNumber: e.target.value });
    }
  };
  handleMagnetTypeChanges = (e) => {
    this.setState({ magnetType: e.target.value });
  };
  handleAlerts = () => {
    return this.state.showPhoneAlert ? (
      <div className="alert-body">ادخل رقم موبيلك الخاص</div>
    ) : null;
  };
  render() {
    const { magnetsMap } = this.state;

    return (
      <section
        className="about-sec parallax-section py-lg-5 py-5"
        id="contact"
        style={{ direction: "ltr", marginTop: "4rem" }}
      >
        <div className="container">
          <Zoom down right>
            <h3 className="form-header-primary">حابب تعرف معلومات اكتر؟</h3>
            <h5 className="form-header-secondary">
              نضم للجروب و اعرف معلومه جديده كل يوم
            </h5>
          </Zoom>

          <div className="inner-sec py-md-5 py-1">
            <div className="choose-main">
              <div id="search_form" className="search_top text-center">
                <Zoom right cascade duration={750}>
                  <form
                    action="#"
                    method="post"
                    className="booking-form row lead-magnet-form"
                  >
                    {this.handleAlerts()}

                    <div className="col-md-3 col-sm-4 col-xs-4 banf">
                      <input
                        className={`form-control ${
                          this.state.showPhoneAlert ? "alert-input" : null
                        }`}
                        type="text"
                        name="Email"
                        placeholder="رقم الموبيل"
                        required
                        onChange={this.validatePhoneNumber}
                      />
                    </div>
                    <div className="col-md-3 col-sm-4  col-xs-4 banf">
                      <select
                        id="country13"
                        className="form-control select-menu"
                        name="group"
                        onClick={this.handleMagnetTypeChanges}
                      >
                        {magnetsMap.map((magnetMap, index) => (
                          <option
                            key={index}
                            value={magnetMap.magnetType}
                            className="select-menu-option"
                          >
                            {magnetMap.magnetType}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-3 col-sm-4 col-xs-4 banf join-button-container">
                      <input
                        className="form-control join-button"
                        data-blast="bgColor"
                        type="submit"
                        value=" انضم للجروب"
                        onClick={this.handleFormSubmission}
                      />
                    </div>
                  </form>
                </Zoom>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

// note #1
// doc takes db handler and then collection name and then an id
// const docRef = doc(db,'clientsNumbers','clientNumber#001');
// const payload={number:"01114889488"}
// works for updating docs
// setDoc(docref,payload)
// addDoc(collectionref,payload)
// collection ref might need an id if am using setDoc as it might be updating the document that did already exist
