import shortid from "shortid";
import "./services.css";
import Service from "../service/service";
import sanityClient from "../../sanity/sanity";
import { useEffect, useState } from "react";
export default function Services() {
  const [services, setServicesData] = useState(null);
  const query = '*[_type == "service"]{image,title,paragraph,imgTitle}';
  useEffect(() => {
    sanityClient
      .fetch(query)
      .then((data) => {
        setServicesData(data);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <section className="banner-bottom-wthree py-md-5 py-3" id="services">
      <div className="container">
        <div className="inner-sec py-md-5 py-3">
          <h3 className="title text-center mb-lg-5 mb-3">خدمات</h3>

          {services !== null
            ? services.map((service) => {
                return (
                  <div
                    key={shortid.generate()}
                    style={{ marginBottom: "4rem", direction: "ltr" }}
                  >
                    <Service
                      title={service.title}
                      image={service.image}
                      imgTitle={service.imgTitle}
                      desc={service.paragraph}
                    ></Service>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </section>
  );
}
