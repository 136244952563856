import "./gallery.css";
import Slider from "react-slick";
import Chevron from "../chevronButton/chevronButton";
import sanityClient from "../../sanity/sanity";
import { useEffect, useState } from "react";
import urlFor from "../../util/imageBuilder";
import Button from "../button/button";

export default function Gallery() {
  const [slides, setAllSlides] = useState(null);
  const query = '*[_type == "gallery"]{image,description,visibility}';
  useEffect(() => {
    sanityClient
      .fetch(query)
      .then((slides) => {
        setAllSlides(slides);
      })
      .catch((e) => console.log(e));
  }, []);
  const sliderSettings = {
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Chevron direction="right" />,
    prevArrow: <Chevron direction="left" />,
    dots: <></>,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: <></>,
        },
      },
      {
        breakpoint: 300,
        settings: {
          nextArrow: <> </>,
          prevArrow: <> </>,
          dots: <></>,
        },
      },
    ],
  };

  const getSlides = () => {
    return slides.map((slide, index) => {
      return (
        <div key={index}>
          <div
            className="gallery__background-image"
            style={{
              backgroundImage: `url(${urlFor(slide.image.asset._ref).url()})`,
            }}
          ></div>
          <div className="button-container">
            {slide.visibility === true ? (
              <Button
                iconName="whatsapp"
                textContent="عرض السعر"
                linkTo={`https://wa.me/+201005000538?text= السلام عليكم محتاج معلومات اكتر عن ${slide.description}`}
              />
            ) : null}
          </div>
        </div>
      );
    });
  };
  return (
    <section id="gallery" className="gallery-section">
      <header>
        <h1> صور طيور للبيع</h1>
      </header>

      <main className="gallery">
        <div className="slider-inner-container">
          <Slider {...sliderSettings}>
            {slides != null ? getSlides() : null}
          </Slider>
        </div>
      </main>
    </section>
  );
}
